import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "mainlayout",
    component: () => import("@/views/layout/MainLayout.vue"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        component: () => import("@/views/home/HomeView.vue"),
        meta: {
          title: ["首页"],
          keepAlive: false,
        },
      },
      {
        path: "/accountdata/accountlist",
        component: () => import("@/views/accountdata/AccountList.vue"),
        meta: {
          title: ["账号数据", "账号列表"],
          keepAlive: false,
        },
      },
      {
        path: "/accountdata/datastatistics",
        component: () => import("@/views/accountdata/DataStatistics.vue"),
        meta: {
          title: ["账号数据", "数据统计"],
          keepAlive: false,
        },
      },
      {
        path: "/aisource/removewatermark",
        component: () => import("@/views/aisource/RemoveWatermark.vue"),
        meta: {
          title: ["AI素材库", "视频去水印"],
          keepAlive: false,
        },
      },
      // {
      //   path:"/videocreate/sourcemanage",
      //   component:()=>import("@/views/videocreate/SourceManage.vue"),
      //   meta:{
      //     title:['视频创作','素材管理'],
      //     keepAlive:false
      //   }
      // },
      {
        path: "/videocreate/newsourcemanage",
        component: () => import("@/views/videocreate/NewSourceManage.vue"),
        meta: {
          title: ["视频创作", "素材管理"],
          keepAlive: false,
        },
      },
      {
        path: "/videocreate/batchclip",
        name: "batchclip",
        component: () => import("@/views/videocreate/BatchClip.vue"),
        meta: {
          title: ["视频创作", "批量剪辑"],
          keepAlive: false,
        },
      },
      {
        path: "/videocreate/videotemplate",
        component: () => import("@/views/videocreate/VideoTemplate.vue"),
        meta: {
          title: ["视频创作", "视频模板"],
          keepAlive: false,
        },
      },
      {
        path: "/videocreate/videopreview",
        component: () => import("@/views/videocreate/VideoPreview.vue"),
        meta: {
          title: ["视频创作", "成片预览"],
          keepAlive: false,
        },
      },
      {
        path: "/videopublish/intellectpublish",
        component: () => import("@/views/videopublish/IntellectPublish.vue"),
        meta: {
          title: ["视频发布", "智能发布"],
          keepAlive: false,
        },
      },
      {
        path: "/videopublish/poimanage",
        component: () => import("@/views/videopublish/POIManage.vue"),
        meta: {
          title: ["视频发布", "POI管理"],
          keepAlive: false,
        },
      },
      // 这是智能发布中创建发布计划页面,是一个表格
      {
        path: "/videopublish/publishplan",
        component: () => import("@/views/videopublish/PublishPlan.vue"),
        meta: {
          title: [],
          keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/Login.vue"),
  },
];
const router = new VueRouter({
  routes,
});

//路由守卫
router.beforeEach((to, from, next) => {
  //这是保存到vuex的token
  // console.log(store.state.commonToken.token);
  const token = localStorage.getItem("token");
  //管理系统两个逻辑，1若访问的是登录页面但是有token，应跳转到首页
  if (to.path == "/login" && token) {
    next("/");
    return;
  }
  //2若访问的不是登录页面，而且没有token，应跳转到登录页面
  if (to.path !== "/login" && !token) {
    next("/login");
    return;
  }
  next();
});
export default router;
