<template>
  <div id="app">
 <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
 
}
</script>

<style lang="less">
@import "./assets/css/main.css";
html,body,#app{
  height: 100vh;
  width: 100vw;
}
// .el-button{
//   background-color: #0f81c8!important;
// }
.el-input__inner::placeholder{
  color: #8a8a8a!important;
}
</style>
