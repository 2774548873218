import Vue from 'vue'
//引入消除默认样式插件
import "reset-css"
//引入particles粒子库
import VueParticles from 'vue-particles';
import App from './App.vue'
// 引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入router
import router from '@/router'
//全局引入lib-flexible,自适应
import 'lib-flexible'
//引入vuex
import store from '@/store'
//引入字体库
import './assets/css/font.css'
Vue.config.productionTip = false
Vue.use(VueParticles)
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
